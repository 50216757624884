import React from 'react';

import ProjectCard from '../components/ProjectCard';
import projects from '../data/projectsData';

const Projects = ({ mobile, styleDark }) => {

    return (
        <section id={styleDark(mobile ? "mobileProjects" : "projects")}>
            <h1>Projects</h1>
            <div>
                {projects.map((project, idx) =>
                    <ProjectCard mobile={mobile} project={project} key={idx} styleDark={styleDark} />
                )}
            </div>
        </section>
    );
};

export default Projects;