import React, { useEffect, useRef, useState } from 'react';
import { FaExternalLinkAlt, FaReact, FaNode, FaPython, FaHtml5, FaCss3, FaBootstrap } from "react-icons/fa";
import { DiMongodb, DiSqllite, DiDotnet } from "react-icons/di";
import { SiJavascript, SiDjango, SiCsharp } from 'react-icons/si';
import { GrMysql } from "react-icons/gr";

import { useRect } from '../hooks/useRect';
import ProjectDetails from '../views/ProjectDetails';

const ProjectCard = ({ mobile, project, styleDark }) => {
    const [selectedProject, setSelectedProject] = useState(null);
    const [active, setActive] = useState('');
    const [currentWindow, setCurrentWindow] = useState({ top: window.scrollY, bottom: window.scrollY + window.innerHeight });

    const elementRef = useRef();
    const top = useRect(elementRef).top;
    const bottom = useRect(elementRef).bottom;

    useEffect(() => {
        return selectedProject ?
            document.body.style.overflow = 'hidden' :
            document.body.style.overflow = 'scroll'
    }, [selectedProject])

    useEffect(() => {
        if (currentWindow.top < top && currentWindow.bottom > bottom) return setActive('Active')
        return setActive('')
    }, [currentWindow, top, bottom])

    const detectY = () => {
        return setCurrentWindow({
            top: window.scrollY + 75,
            bottom: window.scrollY + window.innerHeight
        })
    };

    useEffect(() => {
        window.addEventListener('scroll', detectY);
    }, []);

    const displayIcons = () => {
        let language = project.languages.map((language, idx) => language === 'javascript' ? <SiJavascript id={styleDark(mobile ? `javascript${active}` : 'javascript')} className={styleDark('techIcon')} key={idx} /> :
            language === 'python' ? <FaPython id={styleDark(mobile ? `python${active}` : 'python')} className={styleDark('techIcon')} key={idx} /> :
                language === 'csharp' ? <SiCsharp id={styleDark(mobile ? `csharp${active}` : 'csharp')} className={styleDark('techIcon')} key={idx} /> : <></>);

        let frontend = project.frontend === 'react' ? <FaReact id={styleDark(mobile ? `react${active}` : 'react')} className={styleDark('techIcon')} /> :
            project.frontend === 'html' ? <><FaHtml5 id={styleDark(mobile ? `html${active}` : 'html')} className={styleDark('techIcon')} /><FaCss3 id={styleDark(mobile ? `css${active}` : 'css')} className={styleDark('techIcon')} /></> :
                project.frontend === 'bootstrap' ? <><FaHtml5 id={styleDark(mobile ? `html${active}` : 'html')} className={styleDark('techIcon')} /><FaBootstrap id={styleDark(mobile ? `bootstrap${active}` : 'bootstrap')} className={styleDark('techIcon')} /></> : <></>;

        let backend = project.backend === 'node' ? <FaNode id={styleDark(mobile ? `node${active}` : 'node')} className={styleDark('techIcon')} /> :
            project.backend === 'django' ? <SiDjango id={styleDark(mobile ? `django${active}` : 'django')} className={styleDark('techIcon')} /> :
                project.backend === 'dotnet' ? <DiDotnet id={styleDark(mobile ? `dotnet${active}` : 'dotnet')} className={styleDark('techIcon')} /> : <></>;

        let db = project.database === 'mongodb' ? <DiMongodb id={styleDark(mobile ? `mongodb${active}` : 'mongodb')} className={styleDark('techIcon')} /> :
            project.database === 'sqlite' ? <DiSqllite id={styleDark(mobile ? `sqlite${active}` : 'sqlite')} className={styleDark('techIcon')} /> :
                project.database === 'mysql' ? <GrMysql id={styleDark(mobile ? `mysql${active}` : 'mysql')} className={styleDark('techIcon')} /> : <></>;

        return (
            <>
                {language.map((language) => language)}
                {frontend}
                {backend}
                {db}
            </>
        )
    };

    return (
        <>
            {selectedProject && <ProjectDetails project={selectedProject} setSelectedProject={setSelectedProject} styleDark={styleDark} mobile={mobile} />}
            <div id={styleDark('projectCard')} ref={elementRef} onClick={() => setSelectedProject(project)}>
                <img src={project.screenshots[0]} alt="MotoBuds homepage screenshot" id={styleDark(mobile ? `cardImage${active}` : '')} />
                <h3>{project.title}</h3>
                <p>{project.header}</p>
                <div id={styleDark(mobile ? 'mobileCardIcons' : 'cardIcons')}>
                    <p>{displayIcons()}</p>
                    <p>{!mobile && <>More details</>}<FaExternalLinkAlt className={styleDark(mobile ? `mobileDetailsIcon${active}` : 'detailsIcon')} /></p>
                </div>
            </div>
        </>
    );
};

export default ProjectCard;