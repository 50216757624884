import React from 'react'
import profilePic from '../assets/transparentProfile.png';

const About = ({ mobile, styleDark }) => {

    const aboutDiv = () => (
        <div>
            <h1>About</h1>
            <p>I am a freelance <span>Software Engineer</span> working with some close colleagues at G-Ly Developers, and am looking for new career opportunities.</p>
            <p>After graduating from the <span>University of Washington</span>, I studied computer programming at <span>Coding Dojo (Bellevue)</span>, where I graduated at the top of my cohort. I am currently an active participant in a number of online programming and data science courses.</p>
            <p>My proficiencies are in <span>JavaScript, Python, and C#</span>. In my free time, I enjoy learning new technologies, languages, and frameworks.</p>
            <p>Additional hobbies of mine are motorcycle riding, sailing, chess, traveling, athletics, fantasy sports, and video games.</p>
        </div>
    );

    const aboutImage = () => <img src={profilePic} alt="Joseph Garrett with a blue background" />

    return (
        <section id={styleDark(mobile ? "mobileAbout" : "about")}>
            {!mobile &&
                <>
                    {aboutDiv()}
                    {aboutImage()}
                </>
            }
            {mobile &&
                <>
                    {aboutImage()}
                    {aboutDiv()}
                </>
            }
        </section>
    )
};

export default About;