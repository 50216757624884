import React, { useState } from 'react';

const SchoolCard = ({ mobile, styleDark, school }) => {
    const [menu, setMenu] = useState(false);
    return (
        <div className={styleDark('schoolCard')} onClick={() => setMenu(!menu)}>
            <div>
                <img src={school.image} alt={`Logo for ${school.name}`} />
                <h1>{school.name}</h1>
                <p>{school.dates}</p>
                <p>{school.degree}</p>
            </div>
            <div className={styleDark(menu ? 'menuActive' : 'menuHidden')}>
                {school.achievements.map((item, idx) => {
                    if (item.includes('https'))
                        return <a href={item} target="_blank" rel="noreferrer">Code</a>
                    return (<li key={idx}>{item}</li>)
                })}
                {school.link && <a href={school.link} target="_blank" rel="noreferrer">Course Website</a>}
                {school.cert ?
                    < a href={school.cert} target="_blank" rel="noreferrer">Certificate</a> :
                    <></>}
            </div>
        </div >
    );
};

export default SchoolCard;