const projects = [
    {
        title: 'MotoBuds',
        languages: ['javascript'],
        frontend: 'react',
        backend: 'node',
        database: 'mongodb',
        header: 'MotoBuds is a full-stack social networking application designed to help motorcycle riders plan and join other rides around the world.',
        description: 'MotoBuds allows users to create new group motorcycle rides and to see upcoming rides anywhere in the world. Designed so riders will never have to ride alone again. MotoBuds is a full-stack application utilizing React, Node.js, Express, and MongoDB. Database also constructed using the Mongoose ODM library. MotoBuds uses several node packages such as bcrypt, and JSON Web Tokens. MotoBuds also utilizes several Google APIs including: Maps JavaScript API, Places API, and Geocoding API.',
        screenshots: ['https://i.imgur.com/sNhAo5o.png', 'https://i.imgur.com/kBRMvWI.png', 'https://i.imgur.com/01QHyE7.png', 'https://i.imgur.com/U6iOOhO.png', 'https://i.imgur.com/2Uwqc7I.png'],
        code: 'https://github.com/Jpgarrett24/motobuds',
        demo: 'https://vimeo.com/525843274'
    },
    {
        title: '455 Readiness Tracker',
        languages: ['python'],
        frontend: 'html',
        backend: 'django',
        database: 'sqlite',
        header: "455 Readiness Tracker is a full-stack application designed for the army reserve to track an individual's readiness to serve active duty.",
        description: "455 Readiness implements a permissions-based user network to avoid displaying sensitive information. It is written in Python using the Django framework, and a SQLite database. The application also integrates a CSV loader function to allow the users to quickly upload information into the database. Applied B-Crypt backend validations, and frontend HTML validations for enchanced security. This applications was a collaborative effort with 3 other developers, utilizing Git version control systems and GitHub repositories.",
        screenshots: ['https://i.imgur.com/z23m46b.png'],
        code: 'https://github.com/Jpgarrett24/455_readiness',
    },
    {
        title: 'Vehicle Service Application',
        languages: ['csharp'],
        frontend: 'bootstrap',
        backend: 'dotnet',
        database: 'mysql',
        header: "Vehicle Service Application is a full-stack application that was designed for users to view their vehicle's basic information, and schedule upcoming maintenance services.",
        description: "The Vehicle Service application is a full-stack application written in C#, using the ASP.NET framework and a MySQL database. It is desinged using the MVC architecture. The application also utilizes the Entity Framework Core technology and LINQ queries for object relational mapping. Validations are implemented using the ASP.NET core password hasher, and frontend browser session. The application also applies AJAX/jQuery RESTful API calls to display specific vehicle information from the NHTSA database. This application was a collaborative effort with 2 other developers, utilizing the Git version control system and Github repositories.",
        screenshots: ['https://i.imgur.com/8WfEaPd.png'],
        code: 'https://github.com/Jpgarrett24/Car-Repair-Scheduling'
    },
    {
        title: 'Dojo Property Manager',
        languages: ['javascript'],
        frontend: 'react',
        backend: 'node',
        database: 'mongodb',
        header: "Dojo Property Manager is a full-stack application used to manage rental units at the user's property.",
        description: "Dojo Property Manager is a full-stack web application utilizing React, Node.js, Express, and MongoDB. It integrates AXIOS RESTful API calls to both local and external databases. Dojo Property Manager is designed as a single page application using MVC architecture. Styling also applies the Material-UI framework. Database also constructed using the Mongoose ODM library.",
        screenshots: ['https://i.imgur.com/7BjZcWf.png'],
        code: 'https://github.com/bmichael509/MERN_Project'
    }
];

export default projects;