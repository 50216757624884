import React, { useEffect, useState } from 'react';

import SchoolCard from '../components/SchoolCard';
import schools from '../data/educationData';

const Education = ({ mobile, styleDark }) => {
    const [type, setType] = useState(0);
    const [selectedSchools, setSelectedSchools] = useState(schools.institution);

    useEffect(() => {
        return type === 0 ? setSelectedSchools(schools.institution) : setSelectedSchools(schools.independent);
    }, [type])

    return (
        <section id={styleDark('education')}>
            <h1 id={styleDark(mobile ? 'mobileEducation' : '')}>Education</h1>
            <div id={styleDark(mobile ? 'mobileSchoolToggle' : 'schoolToggle')}>
                <p className={styleDark(type === 0 ? 'schoolTypeActive' : 'schoolTypeInactive')} onClick={() => setType(0)}>Institutional</p>
                <p className={styleDark(type === 1 ? 'schoolTypeActive' : 'schoolTypeInactive')} onClick={() => setType(1)}>Independent</p>
            </div>
            {selectedSchools.map((school) =>
                <SchoolCard mobile={mobile} styleDark={styleDark} school={school} key={school.name} />
            )}
        </section>
    );
};

export default Education;