import React, { useEffect, useState } from 'react'
import DarkModeToggle from 'react-dark-mode-toggle';
import { Link } from 'react-scroll';
import { FaGithubSquare, FaLinkedin, FaRegWindowClose } from 'react-icons/fa';
import { IoMenu } from "react-icons/io5";

import logoWhite from '../assets/logo_white.png';
import logoDark from '../assets/logo_dark.png';

const Navbar = ({ darkMode, mobile, setDarkMode, styleDark }) => {
    const [active, setActive] = useState(false);
    const [shadow, setShadow] = useState("");
    const [toggle, setToggle] = useState(false);

    useEffect(() => {
        const watchForScroll = () => {
            if (window.pageYOffset > 0) {
                setShadow(styleDark("shadow"));
            }
            else {
                setShadow("");
            };
        };

        window.addEventListener('scroll', watchForScroll);
    }, [styleDark]);

    const checkActive = (id) => {
        if (active === false) {
            return ""
        }
        else if (active !== id) {
            return "noHover"
        }
    };

    const displayMobile = () => {
        if (mobile) return "mobileNavDiv";
        else return;
    };

    return (
        <header>
            <nav id={shadow}>
                <div id={styleDark(displayMobile())}>
                    <span>
                        <DarkModeToggle
                            checked={darkMode}
                            onChange={() => { setDarkMode(!darkMode); return localStorage.getItem('darkMode') !== 'true' ? localStorage.setItem('darkMode', 'true') : localStorage.setItem('darkMode', 'false'); }}
                            size={80}
                        />
                    </span>
                    <img src={darkMode ? logoDark : logoWhite} alt="J.G. logo" />
                    <a href="https://www.linkedin.com/in/joseph-garrett-41982188/" target="_blank" rel="noreferrer" className={styleDark("social")}><FaLinkedin /></a>
                    <a href="https://github.com/jpgarrett24" target="_blank" rel="noreferrer" className={styleDark("social")}><FaGithubSquare /></a>
                    {mobile && <IoMenu id={styleDark("mobileBars")} onClick={() => setToggle(true)} />}
                    {mobile &&
                        <ul id={styleDark(toggle ? 'showNav' : 'hideNav')}>
                            <FaRegWindowClose id={styleDark("closeNav")} onClick={() => setToggle(false)} />
                            <Link
                                activeClass="active"
                                duration={500}
                                offset={-50}
                                onClick={() => { return mobile ? setToggle(false) : null }}
                                smooth={true}
                                to="about"
                            >
                                About
                            </Link>
                            <Link
                                activeClass="active"
                                duration={500}
                                offset={-100}
                                onClick={() => { return mobile ? setToggle(false) : null }}
                                smooth={true}
                                to="projects"
                            >
                                Projects
                            </Link>
                            <Link
                                activeClass="active"
                                duration={500}
                                offset={-100}
                                onClick={() => { return mobile ? setToggle(false) : null }}
                                smooth={true}
                                to="education"
                            >
                                Education
                            </Link>
                            <Link
                                activeClass="active"
                                duration={500}
                                offset={-100}
                                onClick={() => { return mobile ? setToggle(false) : null }}
                                smooth={true}
                                to="contact"
                            >
                                Contact
                            </Link>
                        </ul>
                    }
                    {!mobile &&
                        <ul>
                            <li
                                className={styleDark(checkActive(1))}
                                onMouseOver={() => setActive(1)}
                                onMouseLeave={() => setActive(false)}
                            >
                                <Link
                                    activeClass="active"
                                    duration={500}
                                    offset={-50}
                                    onClick={() => { return mobile ? setToggle(false) : null }}
                                    smooth={true}
                                    to="about"
                                >
                                    About
                                </Link>
                            </li>
                            <li
                                className={styleDark(checkActive(2))}
                                onMouseOver={() => setActive(2)}
                                onMouseLeave={() => setActive(false)}
                            >
                                <Link
                                    activeClass="active"
                                    duration={500}
                                    offset={-25}
                                    onClick={() => { return mobile ? setToggle(false) : null }}
                                    smooth={true}
                                    to="projects"
                                >
                                    Projects
                                </Link>
                            </li>
                            <li
                                className={styleDark(checkActive(3))}
                                onMouseOver={() => setActive(3)}
                                onMouseLeave={() => setActive(false)}
                            >
                                <Link
                                    activeClass="active"
                                    duration={500}
                                    offset={-100}
                                    onClick={() => { return mobile ? setToggle(false) : null }}
                                    smooth={true}
                                    to="education"
                                >
                                    Education
                                </Link>
                            </li>
                            <li
                                className={styleDark(checkActive(4))}
                                onMouseOver={() => setActive(4)}
                                onMouseLeave={() => setActive(false)}
                            >
                                <Link
                                    activeClass="active"
                                    duration={500}
                                    offset={-100}
                                    onClick={() => { return mobile ? setToggle(false) : null }}
                                    smooth={true}
                                    to="contact"
                                >
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    }
                </div>
            </nav>
        </header>
    )
};

export default Navbar;