import React from 'react';
import emailjs from 'emailjs-com';
import { BiMessageRoundedDots } from 'react-icons/bi';

const ContactForm = ({ mobile, styleDark }) => {
    function sendEmail(event) {
        event.preventDefault();

        emailjs.sendForm('service_4vnbghb', 'template_9kqklsx', event.target, 'user_zXwVNucelmdjCmDaCfGX1')
            .then((res) => console.log(res))
            .catch((err) => console.log(err));

        event.target.reset();
    }

    return (
        <form onSubmit={sendEmail} id={styleDark(mobile ? 'mobileContactForm' : 'contactForm')} >
            <input type="text" placeholder="Name" name="name" />
            <input type="email" placeholder="Email" name="email" />
            <textarea name="message" placeholder="Your message."></textarea>
            <button><BiMessageRoundedDots id={styleDark('formIcon')} />Send</button>
        </form>
    );
};

export default ContactForm;