import React, { useEffect } from 'react';
import { FaWindowClose, FaPlayCircle, FaGithub } from 'react-icons/fa';
import '../carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

const ProjectDetails = ({ project, setSelectedProject, mobile, styleDark }) => {
    const closeWindow = (event) => {
        return event.keyCode === 27 ? setSelectedProject(null) : null
    }

    useEffect(() => {
        window.addEventListener('keydown', (event) => closeWindow(event));
    });

    return (
        <div id={styleDark(mobile ? 'mobileProjectDetails' : 'projectDetails')} className={styleDark('projectDetails')}>
            <FaWindowClose onClick={() => setSelectedProject(null)} id={styleDark('closeDetails')} />
            {project.screenshots.length > 1 ?
                <div id={styleDark('carouselContainer')}>
                    <Carousel
                        dynamicHeight={true}
                        emulateTouch={true}
                        infiniteLoop={true}
                        showStatus={false}
                        showThumbs={false}
                        width={mobile ? '100%' : '70%'}
                    >
                        {project.screenshots.map((image) =>
                            <div>
                                <img src={image} alt={`Screenshot for the ${project.title} project`} key={image} />
                            </div>
                        )}
                    </Carousel>
                </div> :
                <img src={project.screenshots[0]} alt={`Screenshot for the ${project.title} project`} />}
            <h1 id={styleDark('projectTitle')}>{project.title}</h1>
            <div id={styleDark('projectDetailsLinks')}>
                {project.demo && <a href={project.demo} target="_blank" rel="noreferrer" id={styleDark('playDemoLink')}><FaPlayCircle id={styleDark('playDemo')} />Demo</a>}
                <a href={project.code} target="_blank" rel="noreferrer" id={styleDark('githubLink')}><FaGithub id={styleDark('detailsGithub')} />Code</a>
            </div>
            <p>{project.description}</p>
        </div>
    );
};

export default ProjectDetails;