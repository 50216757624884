import { useEffect, useState } from 'react';
import { Element } from 'react-scroll';

import light from './App.module.css';
import dark from './DarkApp.module.css';

import About from './views/About';
import Contact from './views/Contact';
import Education from './views/Education';
import Navbar from './components/Navbar';
import Projects from './views/Projects';

const App = () => {
  const darkStorage = localStorage.getItem('darkMode');
  const [darkMode, setDarkMode] = useState(false);
  const [mobile, setMobile] = useState(false);

  const styleDark = (name) => {
    return darkMode ? dark[name] : light[name];
  }

  const checkMobile = () => {
    if (window.innerWidth <= 1200)
      return setMobile(true)
    else
      return setMobile(false)
  }

  const scrollToTop = () => window.scrollTo(0, 0);

  useEffect(() => {
    if (darkStorage === 'true') return setDarkMode(true);
  }, [darkStorage])

  useEffect(() => {
    window.addEventListener('beforeunload', scrollToTop);
  });

  useEffect(() => {
    return darkMode ? document.body.style.backgroundColor = '#0d1117' : document.body.style.backgroundColor = '#fff'
  }, [darkMode])

  useEffect(() => checkMobile(), []);

  useEffect(() => {
    window.addEventListener('resize', checkMobile);
  }, [mobile]);

  return (
    <div className="App" id={styleDark("app")}>
      <Navbar
        darkMode={darkMode}
        mobile={mobile}
        setDarkMode={setDarkMode}
        styleDark={styleDark}
      />
      <main>
        <Element name="about">
          <About mobile={mobile} styleDark={styleDark} />
        </Element>
        <Element name="projects">
          <Projects mobile={mobile} styleDark={styleDark} />
        </Element>
        <Element name="education">
          <Education mobile={mobile} styleDark={styleDark} />
        </Element>
        <Element name="contact">
          <Contact mobile={mobile} styleDark={styleDark} />
        </Element>
      </main>
    </div >
  );
}

export default App;
