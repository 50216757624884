const schools = {
    institution: [
        {
            name: 'University of Washington (Seattle)',
            dates: 'Sep. 2012 - Aug. 2016',
            degree: 'Bachelor of Arts (Anthropology)',
            achievements: ["Two time Dean's List"],
            image: 'https://i.imgur.com/iYb3Ynw.png',
            cert: '',
            link: ''
        },
        {
            name: 'Coding Dojo (Bellevue)',
            dates: 'May 2020 - Aug. 2020',
            degree: 'Professional Web Developer Certificate of Completion',
            achievements: ['Black-belt (Highest possible achievement) for all three exams'],
            image: 'https://i.imgur.com/qpe67St.png',
            cert: 'https://drive.google.com/file/d/1opT37Pl9W-QZPpoEqwIzMvUAZ-hhx2vQ/view?usp=sharing',
            link: 'https://www.codingdojo.com/'
        }
    ],
    independent: [
        {
            name: 'The Ultimate React Native Series (CodeWithMosh)',
            dates: 'Jan. 2021 - Feb. 2021',
            degree: 'Certificate of Graduation',
            achievements: ['Built the model app for the course, DoneWithIt', 'https://github.com/Jpgarrett24/DoneWithIt'],
            image: 'https://i.imgur.com/NHK1Qws.png',
            cert: 'https://drive.google.com/file/d/1PLy4n8B9ecv2ezGYucp4j-Zy3K1vdfNM/view?usp=sharing',
            link: 'https://codewithmosh.com/p/the-ultimate-react-native-course'
        },
        {
            name: 'IBM Data Science Professional Certificate (Coursera)',
            dates: 'Nov. 2020 - Apr. 2021 (completion date)',
            degree: 'Professional Certificate of Completion',
            achievements: ['Data Science Orientation', 'Python for Data Science and AI', 'Tools for Data Science', 'Data Science Methodology', 'Data Analysis with Python', 'Machine Learning with Python', 'Data Visualization with Python'],
            image: 'https://i.imgur.com/z94yj52.png',
            cert: 'https://www.youracclaim.com/users/joseph-garrett.588d05c2',
            link: 'https://www.coursera.org/professional-certificates/ibm-data-science'
        },
        {
            name: 'The Ultimate Redux Course (CodeWithMosh)',
            dates: 'Apr. 2021 (completion date)',
            degree: 'Certificate of Graduation',
            achievements: [],
            image: 'https://i.imgur.com/fm9mncT.png',
            cert: '',
            link: 'https://codewithmosh.com/p/ultimate-redux'
        },
        {
            name: 'AWS Fundamentals (Coursera)',
            dates: 'Oct. 2020 - Nov. 2020',
            degree: 'Certificate of Completion',
            achievements: ['Grade Achieved: 99.76%'],
            image: 'https://i.imgur.com/l81bEB1.png',
            cert: 'https://www.coursera.org/account/accomplishments/specialization/PNQ8WFAPDY5W?utm_source=link&utm_medium=certificate&utm_content=cert_image&utm_campaign=sharing_cta&utm_product=s12n',
            link: 'https://www.coursera.org/specializations/aws-fundamentals'
        }
    ]
};

export default schools;