import React from 'react';
import { FaLinkedinIn, FaFileDownload, FaEnvelope } from 'react-icons/fa';

import ContactForm from '../components/ContactForm';

const Contact = ({ mobile, styleDark }) => {
    return (
        <section id={styleDark('contact')} >
            <h1>Contact</h1>
            <div>
                <a href={'mailto: jpgarrett24@gmail.com'} target="_blank" rel="noopener noreferrer"><FaEnvelope className={styleDark('contactIcons')} /></a>
                <a href="https://www.linkedin.com/in/joseph-garrett-41982188/" target="_blank" rel="noreferrer"><FaLinkedinIn className={styleDark('contactIcons')} /></a>
                <a href="https://drive.google.com/file/d/1Mq87xNesxBuvG-C-00TSSCERToO82KEY/view?usp=sharing" target="_blank" rel="noreferrer" ><FaFileDownload className={styleDark('contactIcons')} /> <span>Resume</span></a>
            </div>
            <ContactForm mobile={mobile} styleDark={styleDark} />
        </section>
    );
};

export default Contact;